class BaseUIComponent {
	constructor(context, id) {
		this.context = context;

		this.bus = this.context.UIEventBus;

		this.component = document.getElementById(id);
	}

	show() {
		this.component.style.display = "flex";
	}

	hide() {
		this.component.style.display = "none";
	}

	attach(event, callBack) {
		this.component.addEventListener(event, callBack);
	}
}

export { BaseUIComponent };
