class NavigationMenu {
	constructor(context) {
		this.context = context;

		this.homeNavButton = document.getElementById("home-nav-link");
		this.servicesNavButton = document.getElementById("services-nav-link");
		this.aboutNavButton = document.getElementById("about-nav-link");
		this.contactNavButton = document.getElementById("contact-nav-link");

		this.init();
	}

	init() {
		this.setupEventListeners();
		this.setupEventSubscriptions();
	}

	setupEventListeners() {
		this.homeNavButton.addEventListener("click", () => {
			document
				.getElementById("second-container")
				.scrollIntoView({ behavior: "smooth", block: "start" });
		});

		this.servicesNavButton.addEventListener("click", () => {
			document
				.getElementById("fourth-container")
				.scrollIntoView({ behavior: "smooth", block: "start" });
		});

		this.aboutNavButton.addEventListener("click", () => {
			document
				.getElementById("third-container")
				.scrollIntoView({ behavior: "smooth", block: "start" });
		});

		this.contactNavButton.addEventListener("click", () => {
			console.log("contact");
			document
				.getElementById("contact-page")
				.scrollIntoView({ behavior: "smooth", block: "start" });
		});
	}

	setupEventSubscriptions() {}
}

export { NavigationMenu };
