import * as THREE from "three";

// import "./style.css";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { UIManager } from "./UI-System/UIManager.js";

import { EventBus } from "./Event-System/EventBus.js";

import { G } from "./G.js";

import vs from "./shaders/particle-system/vs.glsl";
import fs from "./shaders/particle-system/fs.glsl";

/*
continue on particle shader material from https://github.com/mrdoob/three.js/blob/master/examples/webgl_buffergeometry_custom_attributes_particles.html
copy the shaders and uniforms and stuff
*/

class Sketch {
	constructor() {
		this.canvas = document.querySelector("canvas.webgl");
		this.scene = new THREE.Scene();

		this.UIEventBus = new EventBus();

		this.lookAt = new THREE.Vector3(0, 0, 0);

		this.clock = new THREE.Clock();
		this.lastElapsedTime = 0;

		this.sizes = {
			width: window.innerWidth,
			height: window.innerHeight,
		};

		this.mouse = {
			x: 0,
			y: 0,
			prevX: 0,
			prevY: 0,
			vX: 0,
			vY: 0,
		};

		this.G = new G(this);

		this.setupScene();

		this.setupHTMLComponents();

		this.mouseEvents();

		this.setupEventSubscription();

		window.addEventListener("resize", this.resize.bind(this));

		this.resize();
	}

	setupHTMLComponents() {
		this.uiManager = new UIManager(this);
	}

	setupEventSubscription() {
		this.UIEventBus.subscribe("light-on", () => {
			this.light.intensity = 0.25;
			this.directionalLight.intensity = 0.5;
			this.scene.background = new THREE.Color("#ccc");
		});

		this.UIEventBus.subscribe("light-off", () => {
			this.light.intensity = 0.3;
			this.directionalLight.intensity = 0.3;
			this.scene.background = new THREE.Color("#212831");
		});
	}

	setupScene() {
		this.addCamera();
		this.setupRenderer();
		this.setupControls();
		this.addLights();
		this.loadLogo();
		this.setupParticleSystem();
	}

	addCamera() {
		this.camera = new THREE.PerspectiveCamera(
			75,
			this.sizes.width / this.sizes.height,
			0.1,
			1000
		);
		this.camera.position.z = 3.5;
		this.scene.add(this.camera);
	}

	setupRenderer() {
		this.renderer = new THREE.WebGLRenderer({
			canvas: this.canvas,
			antialias: true,
			alpha: false,
		});
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.scene.background = new THREE.Color("#212831");
	}

	setupControls() {
		this.controls = new OrbitControls(this.camera, this.canvas);
		this.controls.enableDamping = true;
		this.controls.enableZoom = false;
	}

	addLights() {
		this.light = new THREE.AmbientLight(0xffffff, 0.25);
		this.scene.add(this.light);

		this.directionalLight = new THREE.DirectionalLight(0xdddddd, 0.5);
		this.directionalLight.position.set(0, 0, 1);
		this.scene.add(this.directionalLight);
	}

	loadLogo() {
		this.object = null;
		let loader = new GLTFLoader();
		loader.load("/assets/logo-only.glb", (model) => {
			this.object = model.scene;
			console.log(this.object);

			this.object.position.set(0, -2, 0);

			this.camera.lookAt(this.object.position);

			this.scene.add(this.object);
		});
	}

	setupParticleSystem() {
		this.uniforms = {
			pointTexture: {
				value: new THREE.TextureLoader().load("/assets/spark1.png"),
			},
		};

		this.shaderMaterial = new THREE.ShaderMaterial({
			uniforms: this.uniforms,
			vertexShader: vs,
			fragmentShader: fs,

			blending: THREE.AdditiveBlending,
			depthTest: false,
			transparent: true,
			vertexColors: true,
		});

		this.xSpeed = 0.0005;
		this.ySpeed = 0.001;

		this.particleCount = 4000;
		this.particles = new THREE.BufferGeometry();

		const radius = 150;
		const particles = 2000;

		this.positions = [];
		this.colors = [];
		this.sizes = [];

		const color = new THREE.Color();

		for (var i = 0; i < this.particleCount; i++) {
			this.positions.push((Math.random() * 2 - 1) * radius);
			this.positions.push((Math.random() * 2 - 1) * radius);
			this.positions.push((Math.random() * 2 - 1) * radius);

			color.setHSL(i / particles, 1.0, 0.5);

			// this.colors.push(color.r, color.g, color.b);

			// color.setHSL(i / particles, 1.0, 0.5);

			this.colors.push(1, 1, 1);

			this.sizes.push(20);
		}

		this.particles.setAttribute(
			"position",
			new THREE.Float32BufferAttribute(this.positions, 3)
		);
		this.particles.setAttribute(
			"color",
			new THREE.Float32BufferAttribute(this.colors, 3)
		);
		this.particles.setAttribute(
			"size",
			new THREE.Float32BufferAttribute(this.sizes, 1).setUsage(
				THREE.DynamicDrawUsage
			)
		);

		let material = new THREE.PointsMaterial({ color: 0xff0000 });

		this.points = new THREE.Points(this.particles, this.shaderMaterial);
		this.points.sortParticles = true;

		console.log(this.points);

		this.scene.add(this.points);
	}

	mouseEvents() {
		window.addEventListener("mousemove", (e) => {
			this.mouse.x = e.clientX / this.canvas.width;
			this.mouse.y = e.clientY / this.canvas.height;

			this.mouse.vX = this.mouse.x - this.mouse.prevX;
			this.mouse.vY = this.mouse.y - this.mouse.prevY;

			this.mouse.prevX = this.mouse.x;
			this.mouse.prevY = this.mouse.y;
		});
	}

	resize() {
		let parent = document.getElementById("first-container");
		this.sizes.width = parent.offsetWidth;
		this.sizes.height = window.innerHeight / 2;

		this.camera.aspect = this.sizes.width / this.sizes.height;
		this.camera.updateProjectionMatrix();

		this.renderer.setSize(this.sizes.width, this.sizes.height);
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	}

	updateParticleSystem() {
		this.time = Date.now() * 0.005;
		this.points.rotation.z = 0.01 * this.time;

		const sizes = this.particles.attributes.size.array;

		for (let i = 0; i < this.particleCount; i++) {
			sizes[i] = 10 * (1 + Math.sin(0.1 * i + this.time));
		}

		this.particles.attributes.size.needsUpdate = true;
	}

	animate() {
		const elapsedTime = this.clock.getElapsedTime();
		const deltaTime = elapsedTime - this.lastElapsedTime;
		this.lastElapsedTime = elapsedTime;

		this.updateParticleSystem();

		// Update controls
		this.controls.update();

		// Render
		this.renderer.render(this.scene, this.camera);
		// if (this.object) {
		// 	this.object.rotation.x = lerp(-0, 1, this.mouse.y * 0.5);
		// 	this.object.rotation.y = lerp(-0, 1, this.mouse.x * 0.5);
		// }
		/*
		if (object) object.children[0].rotation.z += deltaTime;
		*/

		// Call tick again on the next frame
		window.requestAnimationFrame(this.animate.bind(this));
	}
}

let sketch = new Sketch();
sketch.animate();

/**
 * Animate
 */

const lerp = (x, y, t) => {
	return (1 - t) * x + t * y;
};
