import { BaseUIComponent } from "../../BaseUIComponent";

class AboutPageComponent extends BaseUIComponent {
	constructor(context, id) {
		super(context, id);

		this.boxesContainer = document.getElementById("service-boxes-container");

		this.serviceBoxItems = [
			new ServiceBoxItem(
				"bolt",
				"ELECTRICAL INSTALLATION",
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maximemollitia, molestiae quas "
			),
			new ServiceBoxItem(
				"videocam",
				"SECURITY CAMERA",
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maximemollitia, molestiae quas "
			),
			new ServiceBoxItem(
				"ev_station",
				"SOME service",
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maximemollitia, molestiae quas "
			),
			new ServiceBoxItem(
				"blender",
				"ELECTRICAL INSTALLATION",
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maximemollitia, molestiae quas "
			),
			new ServiceBoxItem(
				"detector_smoke",
				"ELECTRICAL INSTALLATION",
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maximemollitia, molestiae quas "
			),
			new ServiceBoxItem(
				"tips_and_updates",
				"ELECTRICAL INSTALLATION",
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maximemollitia, molestiae quas "
			),
			new ServiceBoxItem(
				"offline_bolt",
				"ELECTRICAL INSTALLATION",
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maximemollitia, molestiae quas "
			),
			new ServiceBoxItem(
				"power",
				"ELECTRICAL INSTALLATION",
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maximemollitia, molestiae quas "
			),
		];

		this.init();
	}

	init() {
		this.populateServiceBoxes();
	}

	populateServiceBoxes() {
		this.serviceBoxItems.forEach((child) => {
			this.boxesContainer.insertAdjacentHTML(
				"beforeend",
				`
            <div class="boxes-items">
                <div class="icon">
                    <span class="material-symbols-outlined"> ${child.icon} </span>
                </div>
                <div class="title">${child.title}</div>
                <div class="short-explanation">
                   ${child.description}
                </div>
            </div>
            `
			);
		});
	}
}

class ServiceBoxItem {
	constructor(icon, title, description) {
		this.icon = icon;
		this.title = title;
		this.description = description;

		this.init();
	}

	init() {}
}

export { AboutPageComponent };
