import { LogoComponent } from "./MainComponents/NavigationComponent/LogoComponent.js";

import { NavigationMenu } from "./MainComponents/NavigationComponent/NavigationMenu.js";
import { AboutPageComponent } from "./MainComponents/PageComponents/AboutPageComponent.js";

import { HomePageComponent } from "./MainComponents/PageComponents/HomePageComponent.js";

class UIManager {
	constructor(context) {
		this.context = context;

		this.UIBus = this.context.UIEventBus;

		this.el = document.querySelectorAll("*");

		this.lightsOn = true;

		console.log(document.body);

		this.init();
	}

	init() {
		this.logoComponent = new LogoComponent(this.context, "logo");
		this.homePageComponent = new HomePageComponent(this.context, "home");
		this.aboutPageComponent = new AboutPageComponent(this.context, "about");

		this.navigationComponent = new NavigationMenu(this.context);

		const d = new Date();
		let year = d.getFullYear();
		let yr = document.getElementById("year");
		yr.innerText = year;

		console.log(yr);

		this.setupEventSubscriptions();
	}

	setupEventSubscriptions() {
		this.context.UIEventBus.subscribe("light-on", () => {
			this.lightsOn = true;
			console.log("light mode is on");
			// document.querySelector("*").style.background = "white";
			for (var i = 0; i < this.el.length; i++) {
				this.el[i].style.background = "#ccc";
				this.el[i].style.color = "#212831";
			}
		});

		this.context.UIEventBus.subscribe("light-off", () => {
			this.lightsOn = false;
			for (var i = 0; i < this.el.length; i++) {
				this.el[i].style.color = "#fff";
				this.el[i].style.background = "#212831";
			}
		});
	}
}

export { UIManager };
