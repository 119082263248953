import { BaseUIComponent } from "../../BaseUIComponent.js";

class LogoComponent extends BaseUIComponent {
	constructor(context, id) {
		super(context, id);

		this.bus = this.context.UIEventBus;

		console.log("logo component registered");

		// this.component.style.color = "green";
		// this.component.style.cursor = "corsshair";

		this.lightsOn = false;

		this.attach("click", () => {
			if (this.lightsOn) {
				this.lightsOn = false;
				this.bus.publish("light-off");
			} else {
				this.lightsOn = true;
				this.bus.publish("light-on");
			}
		});

		this.init();
	}

	init() {
		this.setupEventSubscriptions();
	}

	setupEventSubscriptions() {
		this.bus.subscribe("light-off", () => {
			this.component.innerText = this.context.G.lang.logo.en;
		});

		this.bus.subscribe("light-on", () => {
			this.component.innerText = this.context.G.lang.logo.am;
		});
	}
}

export { LogoComponent };
