class G {
	constructor(context) {
		this.context = context;

		this.lang = null;

		this.init();
	}

	init() {
		this.initLang();
	}

	initLang() {
		this.lang = {
			logo: {
				am: "ዴልታ",
				en: "DELTA",
			},
		};
	}
}

export { G };
