import { BaseUIComponent } from "../../BaseUIComponent";

class HomePageComponent extends BaseUIComponent {
	constructor(context, id) {
		super(context, id);

		this.title = document.getElementById("home-title");
		this.subtitle = document.getElementById("home-subtitle");
		this.paragraph = document.getElementById("paragraph");
		this.image = document.getElementById("home-image");

		this.init();
	}

	init() {
		this.title.innerText = "DELTA ELECTRICALS";
		this.setupEventListeners();
		this.setupEventSubscribers();
	}

	setupEventListeners() {}

	setupEventSubscribers() {}
}

export { HomePageComponent };
